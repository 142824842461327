<template>
  <div class="page-profile">
    <top-bar :title="$app.secure.clean($n.t('10.1'))" />
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="user-info">
        <div class="image-place">
          <div class="image">
            <img v-if="user.avatar" :src="user.avatar" alt="">
            <n-icon v-else icon="altAvatar" />
            <div class="select-avatar">
              <label>
                <div class="image-place">
                  <n-icon icon="selectAvatar" />
                </div>
                <input type="file" accept="image/jpg/jpeg/png/*" @change="onFileChange" />
              </label>
            </div>
          </div>
        </div>
        <div class="user-content">
          <div class="title">{{ user.fullname }}</div>
          <div class="phone">{{ user.phone }}</div>
          <div class="bin"><t name="3.22" />: {{ user.bin }}</div>
        </div>
      </div>
      <div class="wrap">
        <div class="item">
          <n-link :to="{name: 'my.data'}"></n-link>
          <div class="left">
            <div class="img">
              <n-icon icon="user" />
            </div>
            <div class="title">
              <t name="10.2" />
            </div>
          </div>
          <div class="right">
            <n-icon icon="right-arrow" />
          </div>
        </div>



        <div v-if="!user.isAdmin" class="item" @click="$router.push({name: 'profile.categories'})">
          <div class="left">
            <div class="img">
              <n-icon icon="applications" />
            </div>
            <div class="title">
              <t name="10.11" />
            </div>
          </div>
          <div class="right">
            <n-icon icon="right-arrow" />
          </div>
        </div>

        <!--        <div class="item">-->
        <!--          <div class="left">-->
        <!--            <div class="img"><n-icon icon="bell" /></div><div class="title">Push уведомления</div>-->
        <!--          </div>-->
        <!--          <div class="right"><c-radio /></div>-->
        <!--        </div>-->

        <!--        <div class="item">-->
        <!--          <div class="left">-->
        <!--            <div class="img"><n-icon icon="reload" /></div><div class="title">Стать продавцом</div>-->
        <!--          </div>-->
        <!--          <div class="right"><c-radio /></div>-->
        <!--        </div>-->

<!--        <div class="item">-->
<!--          <div class="left">-->
<!--            <n-link :to="{name: 'tariffs'}"></n-link>-->
<!--            <div class="img">-->
<!--              <n-icon icon="tarif" />-->
<!--            </div>-->
<!--            <div class="title">-->
<!--              <t name="10.3" />-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="right">-->
<!--            <n-icon icon="right-arrow" />-->
<!--          </div>-->
<!--        </div>-->

        <div class="item" @click="$router.push({name: 'myCity'})">
          <div class="left">
            <div class="img">
              <n-icon icon="pin" />
            </div>
            <div class="title">
              <t name="10.4" />
            </div>
          </div>
          <div class="right">
            <n-icon icon="right-arrow" />
          </div>
        </div>

        <div v-if="user.isAdmin" class="item" @click="$router.push({name: 'profile.requests'})">
          <div class="left">
            <div class="img">
              <n-icon icon="applications" />
            </div>
            <div class="title">
              <t name="10.5" />
            </div>
          </div>
          <div class="right">
            <n-icon icon="right-arrow" />
          </div>
        </div>

        <div class="item">
          <n-link :to="{name: 'security'}"></n-link>
          <div class="left">
            <div class="img">
              <n-icon icon="lock" />
            </div>
            <div class="title">
              <t name="10.6" />
            </div>
          </div>
          <div class="right">
            <n-icon icon="right-arrow" />
          </div>
        </div>

        <!--        <div class="item">-->
        <!--          <n-link :to="{name: 'faq'}"></n-link>-->
        <!--          <div class="left">-->
        <!--            <div class="img"><n-icon icon="faq" /></div><div class="title"><t name="10.7" /></div>-->
        <!--          </div>-->
        <!--          <div class="right"><n-icon icon="right-arrow" /></div>-->
        <!--        </div>-->

        <div class="item">
          <n-link :to="{name: 'support'}"></n-link>
          <div class="left">
            <div class="img">
              <n-icon icon="support" />
            </div>
            <div class="title">
              <t name="10.8" />
            </div>
          </div>
          <div class="right">
            <n-icon icon="right-arrow" />
          </div>
        </div>


        <div class="item" @click="changeLang">
          <div class="left">
            <div class="img">
              <n-icon icon="lang" />
            </div>
            <div class="title">
              <t name="10.10" />
            </div>
          </div>
          <div class="right">
            {{ $app.store.getter('app.lang') === 'ru' ? 'kz' : 'ru' }}
          </div>
        </div>

        <div class="item" @click="$app.auth.logout()">
          <div class="left">
            <div class="img">
              <n-icon icon="exit" />
            </div>
            <div class="title">
              <t name="10.9" />
            </div>
          </div>
          <div class="right">
            <n-icon icon="right-arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageIndex',
  data() {
    return {
      user: $app.auth.user(),
      fileId: null,
      file: null,
      url: null,
    }
  },
  created() {
    $app.store.action('app.updateUser')
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0]
      this.url = URL.createObjectURL(this.file)
      this.saveFile()
    },
    saveFile() {
      this.$var('load', true)
      if (this.file.length !== 0) {
        $api.files.create({ file: this.file, }).then((response) => {
          this.fileId = response.data.data.id
          this.editUser()
        }).catch(() => {
          this.$var('load', false)
        })
      }
    },
    editUser() {
      this.$var('load', true)
      $api.user.edit($app.auth.user().id, { avatar: this.fileId, }).then(() => {
        $app.store.action('app.updateUser').finally(() => {
          window.location.reload()
          this.$var('load', false)
        })
      }).catch(() => {
        this.$var('load', false)
      })
    },
    changeLang() {
      $app.store.action('app.setLang', $app.store.getter('app.lang') === 'ru' ? 'kz' : 'ru')
    },
  },
}
</script>

<style scoped lang="scss">
.page-profile {
  .title {
    font-weight: 500;
    font-size: 24px;
    color: #102E4F;
  }

  .phone {
  }

  .phone, .bin {
    font-weight: 400;
    font-size: 15px;
    color: #102E4F;

    span {
      text-transform: uppercase;
    }
  }

  .user-info {
    margin-bottom: 20px;
    .image-place {
      display: flex;
      align-items: center;
      justify-content: center;

      .image {
        width: 90px;
        height: 90px;
        position: relative;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .select-avatar {
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        background-color: #007CFF;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          display: none;
        }

        .image-place {
          border-radius: unset;
          width: 20px;
          height: 16px;

          img {
            width: 100%;
            height: 100%;
            border-radius: unset;
            object-fit: cover;
          }
        }


      }
    }

    .user-content {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 1.2em;
        font-weight: bold;
      }

      p:last-child {
        font-size: 1em;
        color: #6D6F7B;
        font-weight: normal;
      }
    }
  }

  .wrap {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0;
      border-bottom: 1px solid #D9D9D9;
      position: relative;

      .n-link {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }

      .img {
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        ::v-deep svg {
          width: 24px;
          height: 24px;
        }
      }

      .left {
        display: flex;
        align-items: center;
      }
      .right {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .title {
      font-weight: 400;
      font-size: 18px;
      color: #102E4F;
    }
  }
}
</style>
